module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"1bp","short_name":"1bp","start_url":"/","icon":"src/images/logo1bp.svg","background_color":"#f7f0eb","theme_color":"#1e56b6","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f69996938002a1af6c23fe7b7805abf"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Libre Baskerville","file":"https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap"},{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap"},{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
